<template>
  <button @click="showList = !showList" class="btn btn-primary">Add</button>
  <div v-if="showList" class="list-group mt-1">
    <button @click="addSection('heading')" type="button" class="list-group-item list-group-item-action">Heading</button>
    <button @click="addSection('paragraph')" type="button" class="list-group-item list-group-item-action">Paragraph</button>
    <button @click="addSection('code_snippet')" type="button" class="list-group-item list-group-item-action">Code snippet</button>
    <button @click="addSection('carousel_image')" type="button" class="list-group-item list-group-item-action">Image gallery</button>
  </div>
</template>

<script>
  export default {
    name: "AddSectionButton",
    emits: ['sectionSelected'],
    data() {
      return {
        showList: false,
      }
    },
    methods: {
      addSection(type) {
        this.showList = false
        this.$emit('sectionSelected', type);
      }
    }
  }
</script>

<style scoped>
  .list-group-item-action:hover {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }

  .list-group-item-action:focus {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
</style>
