<template>
  <div class="d-flex card">
    <div class="card-body d-flex flex-column">
      <div class="form-group">
        <select v-model="data.paragraph.type" class="form-control">
          <option disabled selected :value="null">Pick the type</option>
          <option v-for="type in types" :value="type">{{ type.charAt(0).toUpperCase() + type.slice(1) }}</option>
        </select>
      </div>

      <div class="form-group">
        <textarea
          @keyup="onKeyUp"
          @keydown.tab.prevent
          v-model="data.paragraph.text"
          class="form-control"
          placeholder="Section content here...">{{ data.paragraph.text }}</textarea>
      </div>
    </div>
  </div>
</template>

<script>
  import consti from '@/const';
  export default {
    name: "Paragraph",
    props: {
      componentData: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        types: consti.pTypes,
        data: this.componentData,
        stack: [],
        tagDetected: false
      }
    },
    methods: {
      onKeyUp(e) {
        e.preventDefault()
        if (e.key === 'Tab') {
          let text = e.target.value
          let selectionStart = e.target.selectionStart
          let textStart = text.slice(0, selectionStart)
          let textEnd = text.slice(selectionStart)

          text = textStart + "\t" + textEnd
          e.target.value = text
          e.target.selectionStart = selectionStart + 1
          e.target.selectionEnd = e.target.selectionStart
        } else if (e.key === '<') {
          this.tagDetected = true
        } else if (e.key === '>') {
          if (this.tagDetected) {
            this.tagDetected = false
            this.stack.push('>')
            this.addTextIntoTextarea(e, this.stack.join(''))
            this.stack = []
          }
        }

        if (this.tagDetected && e.key.length === 1) {
          this.stack.push(e.key)

          if (e.key === '<') {
            this.stack.push('/')
          }
        }

      },
      addTextIntoTextarea(e, text) {
        let currentText = e.target.value
        let selectionStart = e.target.selectionStart
        let textStart = currentText.slice(0, selectionStart)
        let textEnd = currentText.slice(selectionStart)

        currentText = textStart + text + textEnd
        e.target.value = currentText
        e.target.selectionStart = selectionStart
        e.target.selectionEnd = selectionStart
      }
    }
  }
</script>

<style scoped>
  textarea {
    resize: none;
    height: 350px;
  }
</style>
