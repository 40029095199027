<template>
  <div class="d-flex card">
    <div class="card-body d-flex flex-column">
      <div class="row">
        <div class="col-12">
          <img v-for="imageUrl in imageUrls" :src="imageUrl" alt="Image">
          <input @change="handleImageUpload($event, index)" type="file" class="form-control" multiple>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "ImageCarousel",
    props: {
      componentData: {
        type: Object,
        required: true
      }
    },
    created() {
      this.data.images.forEach(item => {
        if ((new RegExp("\/storage\/post\/").test(item))) {
          this.imageUrls.push(item)
        }
      })
    },
    data() {
      return {
        data: this.componentData,
        imageUrls: []
      }
    },
    methods: {
      handleImageUpload(e, i) {
        let $this = this
        $this.data.images = []
        e.target.files.forEach(item => {
          let reader = new FileReader()
          reader.readAsDataURL(item);
          reader.onload = function () {
            $this.data.images.push(reader.result)
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>
