<template>
  <div class="d-flex card">
    <div class="card-body d-flex flex-column">
      <div class="form-group">
        <select v-model="componentData.code_snippet.language" class="form-control">
          <option disabled selected :value="null">Pick the language</option>
          <option v-for="language in languages" :value="language">{{ language }}</option>
        </select>
      </div>

      <div class="form-group">
        <textarea
          @keyup="onKeyUp"
          @keydown.tab.prevent
          v-model="componentData.code_snippet.code"
          class="form-control"
          placeholder="Code here...">{{ componentData.code_snippet.code }}</textarea>
      </div>
    </div>
  </div>
</template>

<script>

    import consti from '@/const';

    export default {
      name: 'CodeSnippet',
      props: {
        componentData: {
          type: Object,
          required: true
        }
      },
      data () {
        return {
          languages: consti.languages
        }
      },
      methods: {
        onKeyUp(e) {
          if (e.key === 'Tab') {
            e.preventDefault()
            let text = e.target.value;
            let selectionStart = e.target.selectionStart;
            let textStart = text.slice(0, e.target.selectionStart)
            let textEnd = text.slice(e.target.selectionStart)

            text = textStart + "\t" + textEnd;
            e.target.value = text
            e.target.selectionStart = selectionStart + 1
            e.target.selectionEnd = e.target.selectionStart
          }
        }
      }
    }
</script>

<style scoped>
  textarea {
    resize: none;
    height: 350px;
  }
</style>
