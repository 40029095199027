<template>
  <div class="d-flex card">
    <div class="card-body d-flex flex-column">
      <div class="row">
        <div class="col-12">
          <input v-model="componentData.heading.text" class="form-control" placeholder="Your heading text here...">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SectionHeading",
    props: {
      componentData: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
